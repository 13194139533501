.modal-outer {
  position: absolute;
  z-index: 100;
}
.modal-wrapper {
  width: 540px;
  border-radius: 24px;
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  margin: 5px auto 5px auto;
  /* margin: 5px; */
  /* transform: translateY(calc(25% - 5px)); */
}

/*Not sure what this is doing...*/
.modal {
  padding: 5%;

  /* border-radius: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-header {
  background: -webkit-linear-gradient(-45deg, #491769 0%, #0d3091 100%);
  height: 35px;
  line-height: 40px;
  padding: 5px 20px;
  text-align: right;
  border-radius: 24px 24px 0 0;
}

.modal-header h3 {
  color: white;
  float: left;
  margin: 0;
  padding: 0;
}

.modal-body {
  /* padding: 10px 15px; */
  /* padding: 5px; */

  text-align: center;
  background: #1d262b;
  max-height: calc(100vh - 90px);
  background: -webkit-linear-gradient(-45deg, #d64eb8 0%, #003fee 100%);
}

.modal-footer {
  background: #263238;
  height: 45px;
  /* padding: 15px; */
  border-radius: 0 0 24px 24px;
}

.close-modal-btn {
  color: white;
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin: 0;
}

.close-modal-btn:hover {
  color: black;
}

.btn-cancel,
.btn-continue {
  border: none;

  cursor: pointer;
  font-weight: bold;
}

.btn-cancel {
  background-color: #b71c1c;
  float: left;
}

.btn-continue {
  float: right;
}

.back-drop {
  background-color: rgba(0, 0, 0, 0.42);
  height: 100%;
  position: fixed;
  transition: all 1.3s;
  width: 100%;
  z-index: 99;
}

.open-modal-btn {
  margin: 15px;
  padding: 10px;
  font-weight: bold;
}

.modal-scroll {
  overflow-y: auto;
  /* overflow-y: overlay; */
  position: relative;
  height: 100%;
}

.modal-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #372e50;
}
.modal-scroll::-webkit-scrollbar {
  width: 5px;
  /* background-color: green; */
}
.modal-scroll::-webkit-scrollbar-thumb {
  background-color: #484d79;
}

@media only screen and (max-width: 768px) {
  .modal-wrapper {
    width: auto;
    margin: 5px;
    height: 100%;

    max-height: calc(100% - 10px);
  }

  .modal-body {
    width: 100%;
    min-height: calc(100vh - 100px);
  }
}
