.btn {
  border-radius: 10px;
  color: white;
  border: 0px;
  font-size: 16px;
  padding: 7px 14px 7px 14px;
  margin: 2px;
  background: -webkit-linear-gradient(-45deg, #954ed6 0%, #009bee 100%);
}

.btn:hover {
  box-shadow: inset 0 0 0 2px yellow;
  cursor: pointer;
}

.btn:active {
  transform: translateY(4px);
}

.btn-delete {
  background: -webkit-linear-gradient(-45deg, #b31515 0%, #ee3b94 100%);
}

.error {
  text-align: center;
  margin: 5px 0;
  padding: 5px 10px;
  color: #c92c43;
}

.alert {
  font-size: 12px;
  padding: 5px;
  background-color: red;
  display: flex;
  flex-direction: column;
}

.alert-danger {
  font-size: 12px;
}

.register-form {
  /* background-color: yellow; */
  display: flex;
}

.register-form-emphasis {
  font-weight: bold;
}

.form-group {
  font-size: 14px;
  /* margin: 5px; */
  /* background-color: green; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.form-container {
  /* background-color: orchid; */
  /* width: 500px; */
  margin: 5px;
  display: flex;
  flex-direction: column;
}

.form-control {
  /* background-color: rgb(33, 33, 53); */
  border-radius: 5px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  border: solid 0px;
  width: 150px;
}

.form-label {
  /* background-color: purple; */
  font-size: 14px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.form-block {
  padding-top: 1px;
  /* background-color: orange; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* justify-items: center;   */
}

.toggle-label {
  margin-right: 5px;
  font-size: 11px;
  color: cyan;
  font-style: italic;
}

.toggle {
  color: white;
  font-size: 10px;
  border-radius: 12px;
  padding: 4px;
  margin-left: 4px;
  width: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  height: 12px;
}

.toggle-text {
  padding: 4px;
  font-weight: bold;
}

.on {
  background-color: #21c975;
  justify-content: flex-end;
}

.off {
  background-color: #949b98;
}

.critical {
  background-color: #ff5ca3;
}

.toggle-pip {
  background-color: white;
  width: 14px;
  height: 14px;
  border-radius: 12px;
  /* transition: 0.2s; */
}

.toggle-translate {
  transform: translateX(18px);
}

.toggle-container {
  display: flex;
  margin-top: 6px;
  flex-direction: row;
  align-items: center;
  /* background-color: blue; */
  justify-content: flex-end;
}

.toggle-container-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: blue; */
  justify-content: flex-end;
}

.toggle-group {
  background-color: rgb(15, 24, 39);
  padding: 12px;
  border-radius: 12px;
  margin: 5px;
  margin-bottom: 12px;
}

.copy-to-clipboard {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: red; */
  margin-right: 25px;
  color: cyan;
  cursor: pointer;
}

.copy-to-clipboard:hover {
  text-decoration: underline;
}

.toggle-clipboard-group {
  /* background-color: green; */
  display: flex;
  margin-bottom: 5px;
}

.info {
  font-size: 14px;
  margin: 5px;
  margin-bottom: 10px;
}
