.front-page-container {
  /* background-color: black; */
  overflow-y: auto;
  scrollbar-width: 6px;
  width: 100%;
  font-size: 14px;
  /* background: green; */
}

.front-page-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #372e50;
}
.front-page-container::-webkit-scrollbar {
  width: 0px;
}
.front-page-container::-webkit-scrollbar-thumb {
  background-color: #484d79;
}

.front-page-text {
  background-color: rgb(25, 25, 36);
  border-radius: 6px;
  padding: 24px;
  margin: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  color: rgb(176, 115, 211);
}

.stat {
  color: rgb(106, 171, 255);
  font-weight: bold;
}

.alert {
  font-size: 14px;
  background-color: rgb(179, 32, 32);
  border-radius: 6px;
  padding: 12px;
}

.bolder {
  font-weight: bold;
}

.front-page-link-container {
  /* background-color: red; */
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* width: 848px; */
}

.fp-card {
  /* background: -webkit-linear-gradient(-45deg, #d64eb8 0%, #003fee 100%); */
  background-color: rgb(39, 39, 53);
  margin: 6px;
  min-width: 192px;
  padding: 12px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
}

.fp-card:hover {
  background-color: rgb(49, 49, 66);
}

.fp-card a:link {
  color: white;
}

.fp-card a:visited {
  color: white;
}

.inline-link a:hover {
  color: rgb(176, 115, 211);
}

.icon-element {
  width: 64px;
  height: 64px;
}

@media screen and (max-width: 768px) {
  .front-page-link-container {
    flex-direction: column;
  }
}

.tos-text-container {
  background: -webkit-linear-gradient(-45deg, #d64eb8 0%, #003fee 100%);
  padding: 24px;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tos-text {
  background-color: rgb(36, 34, 41);
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  padding: 24px;
  /* margin: 24px; */
  border-radius: 24px;
  color: rgb(202, 202, 202);
}

.tos-text:focus {
  outline: 0px;
}

.print-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
  /* background: blue; */
}

.divider {
  margin-left: 6px;
  margin-right: 6px;
}
