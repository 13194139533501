@import "../../../styles/variables.scss";
@import "../../../styles/mixins/buttonStyles.scss";
@import "../../../styles/mixins/inlineStyles.scss";
@import "../mixins/mixins.scss";

.renameChannel {
  &__container {
    @include inline-container;
    align-items: center;
    justify-content: space-between;
    margin: none;
  }
  &__inline-form {
    @include inline;
    background-color: none;
    background: none;
    padding: 0px;
    margin: 0px;
  }
  &__content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__info {
    @include info;
  }
  &__label {
    @include label;
  }
  &__name {
    @include text-area;
  }
  &__alert {
    @include inline-alert;
  }
  &__success {
    @include inline-success;
  }
  &__edit {
    @include button-as-text-gray;
    margin-left: 6px;
  }
  &__action {
    @include button-inline-gray;
  }
  &__action-confirm {
    @include button-inline-delete;
  }
  &__dismiss {
    @include button-as-text-gray;
    color: white;
  }
  &__btn {
    @include button-as-text-gray;
    font-size: 12px;
    padding: 5px 10px 5px 10px;
    background: $button-inactive;
    color: white;
    font-style: normal;
  }
  &__btn:disabled {
    color: gray;
  }

  &__group {
    font-size: 12px;
  }
  &__alert-message {
    display: flex;
    flex: 1;
  }
}
