.members-container {
  background: rgb(25, 25, 36);
  display: flex;
  flex-direction: column;
  margin: 24px;
  margin-top: 12px;
  padding: 12px;
  border-radius: 12px;
}

.members-header {
  margin-top: 12px;
}
