@import "../../../styles/colors.scss";
@import "../mixins/inlineForms.scss";

.inlineForm {
  &__container {
    @include inline-container;
  }
  &__label {
    @include inline-label;
  }
  &__content {
    @include inline-content;
  }
  &__group-container {
    @include inline-group-container;
  }
  &__with-image-container {
    display: flex;
    flex-direction: row;
  }
  &__image-container {
    margin-right: 6px;
  }
  &__form-with-image-container {
    width: 100%;
  }
  &__stack-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 12px;
  }
}
