#no-channel {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    color: grey;
}

#no-channel img {
    display: inline-block;
    height: 1%;
}