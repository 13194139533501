.user {
  color: cyan;
  font-size: 20px;
  padding: 5px 10px 5px 10px;
  text-align: right;
  cursor: pointer;
}

.user-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}

.logo {
  /* width: 200px; */
  height: 28px;
}
.mobile-logo {
  height: 36px;
}

.nav-container {
  display: flex;
  height: 48px;
  background: -webkit-linear-gradient(-45deg, #19191d 0%, #162755 100%);
  width: 100%;
}

.logo-container {
  display: flex;
  flex-direction: row;
  margin: 10px;
  /* margin: 4px;
  padding: 2px; */
}

.logo-container-mobile {
  display: flex;
  flex-direction: row;
  margin: 6px;
  /* border: 1px solid rgb(69, 4, 143); */
  border-radius: 8px;
}

.burger-container {
  display: flex;
  flex-direction: column;
  margin: 6px 0px 6px 6px;

  border-radius: 8px;
  height: 36px;
  min-width: 36px;
  color: white;
  justify-content: center;
}

.burger-container:hover {
  background-color: rgb(46, 46, 104);
}

.patty {
  display: flex;
  background-color: rgb(158, 143, 182);
  height: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 12px;
  margin-left: 6px;
  margin-right: 6px;
}
