.robot-container {
  background-color: rgb(35, 35, 51);
  width: 100%;
  /* background-color: red; */
}

@media screen and (min-width: 769px) {
  .robot-container {
    background-color: rgb(35, 35, 51);
    display: flex;
    flex-direction: column;
    flex: 2;
    /* background-color: red; */
  }
}

.mobile-options-menu {
  background-color: rgb(66, 49, 94);
  margin: 5px;
  margin-top: -5px;
  margin-bottom: 0px;
  position: relative;
  min-height: 52px;
  max-height: 52px;
}

.robot-controls-container {
  background-color: rgb(28, 23, 34);
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  margin-top: 0px;
  /* background-color: red; */
  position: relative;
  /* margin-top: 53px; */
}

[class*="robtn"] {
  background-color: rgb(50, 60, 104);
  border-radius: 24px;
  color: white;
  border: 0px;
  font-size: 16px;
  /* padding: 10px; */
  margin: 4px;
  outline: none;
  padding: 13px 20px 13px 20px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: default;
  touch-action: manipulation;
}

.robtn-admin {
  background-color: #c0376b;
}

.robtn-not-disabled-for-owner {
  background-color: #414141;
  color: rgb(172, 172, 172);
}

.robtn-not-disabled-for-owner:active {
  transform: translateY(4px);
}

.robtn-not-disabled-for-owner:hover {
  box-shadow: inset 0 0 0 2px rgb(5, 214, 186);
  cursor: pointer;
}

.robtn-disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.robtn-admin:hover {
  box-shadow: inset 0 0 0 2px rgb(5, 214, 186);
  cursor: pointer;
}

.robtn-admin:active {
  transform: translateY(4px);
}

.robtn-hot-key {
  padding: 13px 20px 13px 10px;
}

.robtn:hover {
  box-shadow: inset 0 0 0 2px rgb(5, 214, 186);
  cursor: pointer;
}

.robtn:active {
  transform: translateY(4px);
}

[class*="hotkey"] {
  background-color: rgb(73, 83, 131);
  color: rgb(195, 203, 211);
  padding: 6px 12px 6px 12px;
  margin-right: 4px;
  margin-left: 6px;
  border-radius: 7px;
  width: 200px;
}

.robtn-disabled {
  background-color: #414141;
  color: rgb(172, 172, 172);
}

.robtn-disabled-highlight {
  background-color: #4e4e4e;
  color: rgb(172, 172, 172);
}

.hotkey-disabled {
  background-color: #5e5e5e;
  color: rgb(170, 170, 170);
}

.hotkey-disabled-highlight {
  background-color: #777777;
}

.hotkey-admin {
  background-color: #c75f87;
}

.hotkey-admin-highlight {
  background-color: #d37c9d;
}

.hotkey-highlight {
  background-color: rgb(86, 99, 165);
}

.display-info-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 12px;
  left: 0;
  top: 0;
  bottom: 0;
  align-items: flex-start;
  padding: 5px;
}

.display-info {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 2px;
  margin-bottom: 1px;
  display: flex;
  z-index: 10;
}

.robot-display-container {
  margin: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
}

.video-canvas {
  display: flex;
  width: calc(100%);
  min-height: 56.25%; /*16:9 aspect ratio lock */
  /* min-height: calc(16 / 9 * 100%); */
  top: 0;
  bottom: 0;
  z-index: 1;
}

.video-poster {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 0;
  /* transform: translate3d(0, 0, 0); */
}

.display-controls-container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 30%;
  align-self: flex-end;
  flex-direction: row-reverse;
  /* z-index: 5; */
  /* background-color: red; */
}

.display-control {
  z-index: 5;
}

/* .label-container {
  background-color: purple;
} */

.label {
  background-color: rgb(40, 31, 51);
  background: -webkit-linear-gradient(
    0deg,
    rgb(28, 23, 34),
    rgb(40, 31, 51),
    rgb(28, 23, 34)
  );
  color: rgb(175, 175, 175);
  /* display: inline-block; */
  justify-content: center;
  /* width: auto; */
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  /* margin-left: -5px;
  margin-right: -5px; */

  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  /* border-radius: 24px; */
}

.rainbow {
  background: -webkit-linear-gradient(
    0deg,
    #b251eb,
    #ff5ca3,
    #ffa557,
    #f7ff66,
    #7cff1e,
    #00dfd3,
    #1f93ff
  );
  background-clip: text;
  color: transparent;
}

.label-top {
  margin-top: 0px;
}

@media screen and (max-width: 1280px) {
  .robot-display-container {
    width: calc(100% - 10px);
  }
}

@media screen and (max-width: 768px) {
  .robot-controls-container {
    /* background-color: red; */
    /* height: auto; */
    margin-top: 0px;
  }
}
