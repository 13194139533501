@import "../variables.scss";

@mixin live-status {
  background: $color-remo-pink;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 12px;
  font-weight: bold;
  color: white;
}

@mixin browse-server-image {
  width: 128px;
  height: 96px;
  object-fit: cover;
  border-radius: 6px;
  padding: 2px;
}

@mixin browse-server-img-small {
  width: 64px;
  height: 48px;
  object-fit: cover;
  border-radius: 24px;
  padding: 2px;
}

@mixin browse-server-image-live {
  @include browse-server-image();
  box-shadow: inset 0 0 0 2px $color-green-2;
}

@mixin browse-server-container {
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  flex-direction: column;
  width: 144px;
  align-items: center;
  border-radius: 6px;
  margin: 8px;
  &:hover {
    background-color: rgb(42, 42, 68);
    cursor: pointer;
  }
}
