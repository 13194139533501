.App {
  color: white;
  font-size: 20px;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;

  /* overflow-y: auto;
  scrollbar-width: 0px; */
}

body {
  background: #282c34;
}

.App-link {
  color: #61dafb;
}
