.browse-servers-container {
  background-color: rgb(25, 25, 36);
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 120px);
  width: calc(100% - 90px);
  overflow-y: auto;
  display: flex;
}

.browse-robot-server-container {
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  /* background-color: rgb(32, 32, 48); */
  /* background-color: red; */
  flex-direction: column;
  width: 144px;
  align-items: center;
  border-radius: 6px;
  margin: 8px;
}

.browse-display-robot-server-img {
  width: 128px;
  border-radius: 6px;
  padding: 2px;
}
.browse-display-robot-server {
  font-size: 14px;
  font-weight: bold;
  /* color: rgb(176, 115, 211); */
  color: white;
  margin-top: 4px;
}

.browse-display-robot-stats {
  color: rgb(176, 115, 211);
  font-size: 12px;
  margin-top: 2px;
}

.browse-servers-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #372e50;
}

.browse-servers-container::-webkit-scrollbar {
  width: 0px;
}
.browse-servers-container::-webkit-scrollbar-thumb {
  background-color: #484d79;
}

.browse-robot-server-container:hover {
  background-color: rgb(42, 42, 68);
  cursor: pointer;
}

@media only screen and (max-width: 1280px) {
  .browse-servers-container {
    height: auto;
    width: auto;
  }
}
