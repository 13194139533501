@import "../../layout/userProfile/mixins/mixins.scss";
@import "../../../styles/variables.scss";

.LinkPatreon {
  &__container {
    @include info-container;
  }
  &__key {
    @include info-key;
  }
  &__value {
    @include info-value;
  }
  &__info-value-green {
    @include info-value;
    color: $remo-green;
  }
  &__link {
    @include info-edit;
  }
  &__remove {
    @include info-edit-gray;
  }
  &__confirm-container {
    margin-left: 6px;
    padding-bottom: 12px;
  }
  &__info-header {
    @include info-header;
    color: $remo-orange;
  }
}
