@import "../../../styles/variables.scss";
@import "../../../styles/mixins/buttonStyles.scss";
@import "../mixins/mixins.scss";

.DeleteChannelForm {
  &__container {
    @include inline-container;
    justify-content: space-between;
  }
  &__label {
    @include label;
    color: $color-remo-pink;
  }
  &__action {
    @include button-inline-gray;
  }
  &__action-confirm {
    @include button-inline-delete;
  }
}
