@import "../variables.scss";

//ooof why do i have 2, why do i do this to myself...
@mixin inline {
  display: flex;
  flex-direction: row;
  padding: 9px;
  background-color: $color-dark-cool-gray;
  margin: 2px;
  border-radius: 6px;
  font-size: 14px;
  align-items: center;
}

//old, want to kill
@mixin inline-container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 1px;
  align-items: center;
  // justify-items: center;
}

@mixin inline-label {
  font-weight: bold;
  padding-right: 6px;
  color: rgb(106, 171, 255);
}

@mixin inline-content {
  font-size: 14px;
  color: white;
}

@mixin inline-success {
  @include inline;
  background-color: $color-green-background;
}

@mixin inline-alert {
  @include inline;
  background-color: $color-error-text;
}

@mixin text-area {
  border: 0px;
  border-radius: 12px;
  height: 15px;
  display: flex;
  flex: 2;
  background-color: $color-victoria-1;
  resize: none;
  color: white;
  font-size: 12px;
  padding: 8px;
  margin-bottom: 2px;
  margin-left: 12px;
}
