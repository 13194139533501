.register-form {
  padding: 5%;
  background: -webkit-linear-gradient(-45deg, #d64eb8 0%, #003fee 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.intro {
  border-radius: 12px;
  margin-bottom: 24px;
  margin-top: 24px;
  background: rgb(25, 25, 36);
}

.landing-page {
  padding: 24px;
  font-size: 15px;
}

.landing-logo {
  height: 50%;
  width: 50%;
}

.select-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: -30px;
  z-index: 100;
  font-size: 18px;
}

.select-option {
  cursor: pointer;
  color: #1f93ff;
}

.select-option:hover {
  color: #00dfd3;
}

.widget-container {
  border-radius: 24px;
  /* background-color: blue; */
  background: rgb(25, 25, 36);
  margin-top: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -12px;
}

.widget-container-modal {
  background: rgb(25, 25, 36);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.widget-modal-feedback {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background: -webkit-linear-gradient(-45deg, #b31515 0%, #ee3b94 100%);

  padding: 12px;
}

.spacer {
  margin-right: 12px;
  padding-left: 6px;
}

.selected-option {
  color: #00dfd3;
}

.forgot-password {
  margin-top: 12px;
}
