@import "../../../styles/colors.scss";

@mixin inline-group-container {
  background: $color-form-group-container;
  display: flex;
  flex-direction: column;
  margin: 24px;
  margin-top: 12px;
  padding: 12px;
  border-radius: 12px;
  width: 100%;
}

@mixin inline-container {
  padding: 12px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  background: $color-inline-container;
  margin-bottom: 2px;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}

@mixin inline-label {
  color: $color-remo-blue;
  font-weight: bold;
  align-self: center;
}

@mixin inline-content {
  color: $color-content-default;
}
