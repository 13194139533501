@import "../../layout/userProfile/mixins/mixins.scss";
@import "../../../styles/mixins/inlineStyles.scss";

.VerifyEmail {
  &__link {
    @include info-edit;
  }
  &__container {
    @include info-container;
  }
  &__key {
    @include info-key;
  }
  &__value {
    @include info-value;
  }
  &__success {
    @include inline-success;
  }
  &__error {
    @include inline-alert;
  }
  &__dismiss {
    @include info-edit;
    padding: 12px;
    color: white;
    text-decoration: underline;
  }
  &__inline {
    @include inline;
  }
}
