@import "../../../styles/mixins/inlineStyles.scss";

.inlineProfileItem {
  &__container {
    @include inline-container;
  }
  &__label {
    @include inline-label;
  }
  &__info {
    @include inline-content;
  }
}
